import type { FunctionComponent } from 'react';
import React from 'react';

import styles from './Spinner.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type SpinnerProps = {
  className?: string;
};

const Spinner: FunctionComponent<SpinnerProps> = ({ className }) => {
  return <div className={mergeClassNames([styles.spinner, className])} />;
};

export default Spinner;
