import type { FunctionComponent, ReactElement, ReactNode } from 'react';
import React from 'react';
import type { RequireExactlyOne } from 'type-fest';

import styles from './Responsive.module.scss';

import type {
  ResponsiveDevice,
  ResponsiveDeviceExtended,
} from 'src/utils/ResponsiveDetect.Utils';
import { ResponsiveDeviceEnum } from 'src/utils/ResponsiveDetect.Utils';

type ResponsiveProps = RequireExactlyOne<{
  is: ResponsiveDeviceExtended;
  isNot: ResponsiveDeviceExtended;
  isOrLarger: ResponsiveDevice;
  isOrSmaller: ResponsiveDevice;
}> & {
  dataTestId?: string;
  fullDimensions?: boolean;
} & (
    | {
        cloneChild?: false;
        children: ReactNode;
      }
    | {
        // Clone the child instead of wrapping (the child must accept a className prop)
        cloneChild: true;
        children: ReactElement;
      }
  );

const classNamesByDevice: Record<ResponsiveDevice, string> = {
  xSmallMobile: styles.hideForMobileXS,
  smallMobile: styles.hideForMobileS,
  mediumDesktop: styles.hideForDesktopMd,
  largeDesktop: styles.hideForDesktopL,
  xLargeDesktop: styles.hideForDesktopXL,
};

const allDevices = Object.keys(classNamesByDevice) as ResponsiveDevice[];

const getResponsiveClassNames = ({
  is,
  isNot,
  isOrLarger,
  isOrSmaller,
}: Omit<ResponsiveProps, 'children'>) => {
  // Handle groups
  if (is === 'mobile' || isNot === 'desktop') {
    return [styles.hideForDesktop];
  }

  if (is === 'desktop' || isNot === 'mobile') {
    return [styles.hideForMobile];
  }

  // Handle individual devices
  if (is) {
    return allDevices
      .filter((item) => item !== is)
      .map((item) => classNamesByDevice[item]);
  }

  if (isNot) {
    return [classNamesByDevice[isNot]];
  }

  // Handle bigger/smaller groups
  if (isOrLarger) {
    return allDevices
      .filter(
        (item) => ResponsiveDeviceEnum[item] < ResponsiveDeviceEnum[isOrLarger],
      )
      .map((item) => classNamesByDevice[item]);
  }

  return allDevices
    .filter(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (item) => ResponsiveDeviceEnum[item] > ResponsiveDeviceEnum[isOrSmaller!],
    )
    .map((item) => classNamesByDevice[item]);
};

const Responsive: FunctionComponent<ResponsiveProps> = ({
  fullDimensions,
  dataTestId,
  ...otherProps
}) => {
  const className = [
    fullDimensions && styles.container,
    ...getResponsiveClassNames(otherProps),
    otherProps.cloneChild && otherProps.children.props.className,
  ]
    .filter(Boolean)
    .join(' ');

  return !otherProps.cloneChild ? (
    <div data-testid={dataTestId} className={className}>
      {otherProps.children}
    </div>
  ) : (
    React.cloneElement(otherProps.children, {
      className,
    })
  );
};

export default Responsive;
