import { useState, useEffect } from 'react';

import { isClientSide } from './EnvUtils';
import { getWindowHeight, getWindowWidth } from './WindowUtils';

/**
 ** 320                 768                  1024                 1366                 1920                  ...
 ** |      MOBILE_XS     |      MOBILE_S      |     DESKTOP_MD      |     DESKTOP_L      |     DESKTOP_XL     |
 ** |                 MOBILE                  |                             DESKTOP
 */

const MOBILE_XS_WIDTH = { max: 768 } as const;
const MOBILE_S_WIDTH = { min: 768, max: 1024 } as const;
const DESKTOP_MD_WIDTH = { min: 1024, max: 1366 } as const;
const DESKTOP_L_WIDTH = { min: 1366, max: 1920 } as const;
const DESKTOP_XL_WIDTH = { min: 1920 } as const;

export enum ResponsiveDeviceEnum {
  'xSmallMobile' = 320,
  'smallMobile' = 768,
  'mediumDesktop' = 1024,
  'largeDesktop' = 1366,
  'xLargeDesktop' = 1920,
}

export type ResponsiveDevice = keyof typeof ResponsiveDeviceEnum;
export type ResponsiveDeviceExtended = ResponsiveDevice | 'mobile' | 'desktop';

const getWindowDimensions = () => {
  return {
    width: getWindowWidth(),
    height: getWindowHeight(),
  };
};

const useJavaScriptResponsiveWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(() =>
    getWindowDimensions(),
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isClientSide()) {
    throw new Error(
      'useJavaScriptResponsiveWindowDimensions is only available on client side',
    );
  }

  return windowDimensions;
};

export const useJavaScriptResponsiveDetect = () => {
  const { height, width } = useJavaScriptResponsiveWindowDimensions();

  const [isXSmallMobile, setXSmallMobile] = useState(false);
  const [isSmallMobile, setSmallMobile] = useState(false);
  const [isMediumDesktop, setIsMediumDesktop] = useState(false);
  const [isLargeDesktop, setIsLargeMobile] = useState(false);
  const [isXLargeDesktop, setIsXLargeMobile] = useState(false);

  useEffect(() => {
    setXSmallMobile(width < MOBILE_XS_WIDTH.max);
    setSmallMobile(width >= MOBILE_S_WIDTH.min && width < MOBILE_S_WIDTH.max);

    setIsMediumDesktop(
      width >= DESKTOP_MD_WIDTH.min && width < DESKTOP_MD_WIDTH.max,
    );
    setIsLargeMobile(
      width >= DESKTOP_L_WIDTH.min && width < DESKTOP_L_WIDTH.max,
    );
    setIsXLargeMobile(width >= DESKTOP_XL_WIDTH.min);
  }, [height, width]);

  if (!isClientSide()) {
    throw new Error(
      'useJavaScriptResponsiveDetect is only available on client side',
    );
  }

  const getDevice = () => {
    if (isXSmallMobile) {
      return ResponsiveDeviceEnum.xSmallMobile;
    }

    if (isSmallMobile) {
      return ResponsiveDeviceEnum.smallMobile;
    }

    if (isMediumDesktop) {
      return ResponsiveDeviceEnum.mediumDesktop;
    }

    if (isLargeDesktop) {
      return ResponsiveDeviceEnum.largeDesktop;
    }

    return ResponsiveDeviceEnum.xLargeDesktop;
  };

  return {
    device: getDevice(),
    isXSmallMobile,
    isSmallMobile,
    isMediumDesktop,
    isLargeDesktop,
    isXLargeDesktop,
    isMobile: isXSmallMobile || isSmallMobile,
    isDesktop: isMediumDesktop || isLargeDesktop || isXLargeDesktop,
  };
};
