import type { FunctionComponent } from 'react';
import React from 'react';

import styles from './Container.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type ContainerProps = {
  className?: string;
  fluid?: boolean | string | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const Container: FunctionComponent<ContainerProps> = ({
  className,
  fluid,
  onClick,
  children,
}) => {
  const fluidClass = typeof fluid === 'string' ? `${fluid}` : 'fluid';
  const baseClass = fluid ? fluidClass : 'container';
  return (
    <div
      className={mergeClassNames([styles[baseClass], className])}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Container;
