// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
// eslint-disable-next-line import/no-unresolved
import type { ParsedUrlQueryInput } from 'querystring';
import type { FunctionComponent } from 'react';
import React from 'react';
import type { SetRequired } from 'type-fest';

import { extractDomain } from 'src/utils/JavascriptUtils';

type LinkProps = {
  query?: ParsedUrlQueryInput | null;
  prefetch?: boolean;
  legacy?: boolean;
};

const Link: FunctionComponent<
  SetRequired<JSX.IntrinsicElements['a'], 'children' | 'href'> & LinkProps
> = ({ children, href, query, prefetch = false, legacy, ...otherProps }) => {
  const isInternalLink =
    extractDomain(href) ===
    extractDomain(process?.env.SITE_URL ?? window.location.href);

  const isExternalLink = !isInternalLink && href.startsWith('http');
  const isTelOrMail = href.startsWith('tel:') || href.startsWith('mail:');

  if (!isExternalLink && !isTelOrMail && !legacy) {
    return (
      <NextLink
        href={!query ? href : { pathname: href, query }}
        prefetch={prefetch}
      >
        {/* eslint-disable-next-line react/forbid-elements */}
        <a {...otherProps}>{children}</a>
      </NextLink>
    );
  }

  return (
    // eslint-disable-next-line react/forbid-elements
    <a
      href={href}
      {...(!isTelOrMail && !legacy
        ? {
            target: '_blank',
            rel: `noreferrer${isExternalLink ? ' nofollow' : ''}`,
          }
        : isExternalLink
        ? { rel: 'nofollow' }
        : {})}
      {...otherProps}
    >
      {children}
    </a>
  );
};

export default Link;
