import styles from './Col.module.scss';

const minBreakpoint = 'xs';
const prefix = 'col';

type NumberAttr =
  | number
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

type ColSize = boolean | 'auto' | NumberAttr;
type ColSpec = ColSize | { span?: ColSize };

export type ColProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  xs?: ColSpec;
  sm?: ColSpec;
  md?: ColSpec;
  lg?: ColSpec;
  xl?: ColSpec;
  xxl?: ColSpec;
};

export function useCol({ className, ...breakpoints }: ColProps) {
  const spans: string[] = [];

  Object.keys(breakpoints).forEach((brkPoint) => {
    const propValue: ColSpec = breakpoints[brkPoint as keyof ColSpec];

    const span =
      typeof propValue === 'object' && propValue !== null
        ? propValue['span']
        : propValue;

    const infix = brkPoint !== minBreakpoint ? `-${brkPoint}` : '';

    if (span)
      spans.push(
        span === true
          ? styles[`${prefix}${infix}`]
          : styles[`${prefix}${infix}-${span}`],
      );
  });

  return {
    classNames: spans,
  };
}
