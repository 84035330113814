import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';

import styles from './Title1.module.scss';

export type Title1Props = JSX.IntrinsicElements['h1'] & {
  className?: string;
  children: ReactNode;
};

const Title1: FunctionComponent<Title1Props> = (props) => {
  const { className, children, ...otherProps } = props;
  return (
    <div className={`${styles.title1} ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default Title1;
