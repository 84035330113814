export const debounce = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (...args: any[]) => void,
  timeToWait: number,
  { leading = false, trailing = true } = {},
) => {
  let timeout: number | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    const later = () => {
      timeout = null;

      if (trailing) {
        func(...args);
      }
    };

    const callNow = leading && !timeout;
    clearTimeout(timeout || undefined);
    // Internal Node.js types conflict
    timeout = setTimeout(later, timeToWait) as unknown as number;

    if (callNow) {
      func(...args);
    }
  };
};

export const sortBy = (property: string) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substring(1);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (a: any, b: any) => {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const omitUrlQueryParams = (url: string) => {
  return url.split('?')[0];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unique = (element: any, index: number, self: any) => {
  return self.indexOf(element) === index;
};

export const formatPrice = (price: string, decimalPlaces = 2) => {
  const floatPrice = parseFloat(price.replace(',', '.'));

  const formattedPrice = floatPrice.toFixed(decimalPlaces);

  return formattedPrice;
};

export const removeQueryParams = (url: string, keywords: string[]): string => {
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;

  const keysToDelete: string[] = [];

  searchParams.forEach((_, key) => {
    if (keywords.some((keyword) => key.includes(keyword))) {
      keysToDelete.push(key);
    }
  });

  keysToDelete.forEach((key) => searchParams.delete(key));
  const modifiedSearch = searchParams.toString();

  const queryString = modifiedSearch ? '?' + modifiedSearch : '';

  return urlObject.origin + urlObject.pathname + queryString + urlObject.hash;
};

export function pickQueryParams(url: string, paramNames: string[]): string {
  const urlObject = new URL(url);

  const searchParams = urlObject.searchParams;

  const keysToDelete: string[] = [];
  searchParams.forEach((_, key) => {
    if (!paramNames.includes(key)) {
      keysToDelete.push(key);
    }
  });

  keysToDelete.forEach((key) => {
    searchParams.delete(key);
  });

  urlObject.search = searchParams.toString();

  return urlObject.toString();
}

export const extractDomain = (url: string) => {
  const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/);
  return match ? match[1] : null;
};
