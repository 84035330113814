import type { FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import Script from 'react-load-script';

import { Rating, Image } from 'src/components/shared/index';

import style from './InlineCritizr.module.scss';

import {
  getDataTestId,
  mergeClassNames,
  usePageType,
} from 'src/utils/ReactUtils';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    CritizrSDK: {
      open: (type: 'modal:store_display', platform: string) => void;
    };
  }
}

export type InlineCritizrProps = {
  averageRating: number;
  totalRatings?: number;
  className?: string;
  txtRating?: string;
  txtRatingColor?: string;
  starsPosition?: 'left' | 'right';
  actorId?: string;
  modalIsShowed?: boolean;
  hasLogo?: boolean;
  dataTestId?: string;
  minimized?: boolean;
  trackingCallback?: () => void;
  isCondensed?: boolean;
};

const InlineCritizr: FunctionComponent<InlineCritizrProps> = ({
  averageRating,
  totalRatings,
  className,
  txtRating,
  txtRatingColor,
  starsPosition,
  actorId = 'Web',
  modalIsShowed,
  hasLogo,
  dataTestId,
  minimized = false,
  trackingCallback,
  isCondensed = false,
}) => {
  const pageType = usePageType();
  const [showModal, setShowModal] = useState(false);

  const gtmTrackingHandler = (dataLayer: unknown) => {
    TagManager.dataLayer({
      dataLayer: dataLayer as never,
    });
  };
  useEffect(() => {
    if (showModal) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageview',
          virtualPagePath: '/pop-in/avis-client',
          virtualPageTitle: 'Avis client',
        },
      });
    }
  }, [showModal]);

  useEffect(() => {
    if (typeof modalIsShowed === 'boolean') {
      setShowModal(modalIsShowed);
    }
  }, [modalIsShowed]);

  const openModalAvis = () => {
    if (window.CritizrSDK) {
      window.CritizrSDK.open('modal:store_display', actorId);
    }
    setShowModal(false);
  };

  if (averageRating <= 0) {
    return <>Aucun avis</>;
  }

  return (
    <>
      <div
        className={mergeClassNames([style.critizr, className ?? ''])}
        data-testid="inline_critizr"
        onClick={(e) => {
          e.stopPropagation();

          if (!showModal) {
            setShowModal(true);
            if (trackingCallback) {
              trackingCallback();
              return;
            }
            gtmTrackingHandler({
              event: 'eventGA',
              eventCategory: 'moteur recherche de prestation',
              eventAction: 'clic avis clients',
              eventLabel: '/',
              pageType,
            });
          }
        }}
        {...getDataTestId({ dataTestId })}
      >
        <div className={style.rating}>
          {isCondensed && (
            <span className={style.condensedCritizr}>Avis clients</span>
          )}
          <span
            style={{ order: starsPosition === 'right' ? 2 : undefined }}
            className={`d-flex ${style.ratingStars}`}
          >
            <Rating
              averageRating={averageRating}
              iconSize={isCondensed ? 24 : 16}
              minimized={minimized}
            />
          </span>
          <span
            style={{ marginRight: starsPosition === 'right' ? 17 : undefined }}
            className={mergeClassNames([
              style.ratingText,
              txtRatingColor && style[`ratingText${txtRatingColor}`],
            ])}
          >
            <b data-testid="inline_critizr_average">
              {averageRating?.toPrecision(3)}/5
            </b>{' '}
            <span
              className={minimized ? style.minimizedRatingText : ''}
              data-testid="inline_critizr_total"
            >
              sur {totalRatings} avis
            </span>
            {!minimized && (
              <span className={isCondensed ? style.condensedCritizr : ''}>
                {' '}
                Goodays
              </span>
            )}
          </span>
        </div>

        {(hasLogo || txtRating) && (
          <div className={style.infos}>
            {hasLogo && (
              <div className={style.infosLogo}>
                <Image
                  src="/images/components/InlineCritizr/brown-goodays.png"
                  width={70}
                  height={15}
                  alt={
                    txtRating?.trim().length
                      ? txtRating
                      : `avis ${averageRating?.toPrecision(3)}/5`
                  }
                />
              </div>
            )}
            {txtRating?.length && (
              <>
                {txtRating.trim().length && (
                  <span className={style.infosText}> {txtRating} </span>
                )}
              </>
            )}
          </div>
        )}
        {showModal && (
          <Script
            url="https://static.critizr.com/widgets/1bdaccbf9e6fb7893d0b694727f05a2c.js"
            onLoad={openModalAvis}
          />
        )}
      </div>
    </>
  );
};

export default InlineCritizr;
