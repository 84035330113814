import type { FunctionComponent } from 'react';
import React from 'react';

import type { ColProps } from './useCol';
import { useCol } from './useCol';

import { mergeClassNames } from 'src/utils/ReactUtils';

const Col: FunctionComponent<ColProps> = (props) => {
  const { children, className, onClick } = props;

  const { classNames } = useCol(props);
  return (
    <div
      className={mergeClassNames([...classNames, className])}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Col;
