import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';

import styles from './Title3.module.scss';

export type Title3Props = {
  className?: string;
  children: ReactNode;
};

const Title3: FunctionComponent<Title3Props> = ({
  className,
  children,
  ...otherProps
}) => {
  return (
    <div className={`${styles.title3} ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default Title3;
