import Head from 'next/head';
import type { FunctionComponent } from 'react';
import React from 'react';

type MetaTitleProps = {
  title: string;
};

const MetaTitle: FunctionComponent<MetaTitleProps> = ({ title }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta key="og:title" property="og:title" content={title} />
      <meta key="twitter:title" name="twitter:title" content={title} />
    </Head>
  );
};

export default MetaTitle;
