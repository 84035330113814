import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';

import styles from './Title2.module.scss';

export type Title2Props = {
  className?: string;
  children: ReactNode;
};

const Title2: FunctionComponent<Title2Props> = ({
  className,
  children,
  ...otherProps
}) => {
  return (
    <div className={`${styles.title2} ${className}`} {...otherProps}>
      {children}
    </div>
  );
};

export default Title2;
