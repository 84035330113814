import type { FunctionComponent } from 'react';
import React from 'react';

import { Icon, Link } from '../..';
import type { IconType } from '../../Icon/types';
import styles from './ButtonTertiary.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type ButtonTertiaryProps = {
  url?: string;
  color?: 'White' | 'Brown' | 'Green';
  leftIcon?: string;
  rightIcon?: string;
  handleOnClick?: () => void;
  dataTestId?: string;
  rel?: string;
} & JSX.IntrinsicElements['button'];

const ButtonTertiary: FunctionComponent<ButtonTertiaryProps> = ({
  className,
  children,
  url,
  color,
  leftIcon,
  rightIcon,
  handleOnClick,
  dataTestId,
  rel,
  ...otherProps
}) => {
  const buttonClassName = mergeClassNames([
    styles.btnTertiary,
    color && styles['btnTertiary' + color],
    className,
  ]);

  const Content = (
    <>
      {leftIcon && (
        <Icon
          name={leftIcon as IconType}
          className={styles.content}
          width={32}
          height={32}
        />
      )}
      <span className={styles.content}>{children}</span>
      {rightIcon && (
        <Icon
          name={rightIcon as IconType}
          className={mergeClassNames([styles.iconRight, styles.content])}
          width={32}
          height={32}
        />
      )}
    </>
  );

  if (url) {
    return (
      <Link
        href={url}
        onClick={handleOnClick}
        className={buttonClassName}
        data-testid={dataTestId}
        {...(rel ? { rel } : {})}
      >
        {Content}
      </Link>
    );
  }

  return (
    <button
      type={'button'}
      className={buttonClassName}
      data-testid={dataTestId}
      {...otherProps}
    >
      {Content}
    </button>
  );
};

export default ButtonTertiary;
