import dynamic from 'next/dynamic';
import React from 'react';

import { mergeClassNames } from '../../../utils/ReactUtils';
import styles from './Select.module.scss';

export type SelectOptionWithGroups<Value = string> =
  | {
      value: Value;
      label: string;
    }
  | {
      label: string;
      options: {
        value: Value;
        label: string;
      }[];
    };

export type SelectOption<Value = string> = {
  value: Value;
  label: string;
};

export type SelectProps<Value = string> = {
  value?: SelectOption<Value> | null;
  options: SelectOptionWithGroups<Value>[] | undefined;
  onChange: (option: SelectOption<Value> | null) => void;
  isSearchable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  className?: string;
  placeholder?: string;
  id?: string;
  isMulti?: boolean;
  maxMenuHeight?: number;
  menuIsOpen?: boolean;
  onInputChange?: (
    inputValue: string,
    { action }: { action: string },
  ) => string;
};

const SelectDynamic = dynamic(() => import('react-select'));

const Select = <Value,>({ className, ...otherProps }: SelectProps<Value>) => {
  return (
    // @ts-expect-error (generic types broken by next/dynamic)
    <SelectDynamic
      classNamePrefix="customSelect"
      className={mergeClassNames([styles.customSelect, className])}
      {...otherProps}
      menuPlacement={'auto'}
      noOptionsMessage={() => 'Aucun résultat'}
      loadingMessage={() => 'Chargement...'}
    />
  );
};

export default Select;
