// eslint-disable-next-line no-restricted-imports
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { isClientSide } from 'src/utils/EnvUtils';

export const getWindowHeight = () => {
  if (!isClientSide()) {
    return 0;
  }

  const e = document.documentElement;
  const g = document.getElementsByTagName('body')[0];

  return e.clientHeight || window.innerHeight || g.clientHeight;
};

export const getWindowWidth = () => {
  if (!isClientSide()) {
    return 0;
  }

  const e = document.documentElement;
  const g = document.getElementsByTagName('body')[0];

  return e.clientWidth || window.innerWidth || g.clientWidth;
};

export const disableScroll = (focusedElement: HTMLElement | Element) => {
  disableBodyScroll(focusedElement, {
    reserveScrollBarGap: true,
  });
};

export const enableScroll = (focusedElement: HTMLElement | Element) => {
  enableBodyScroll(focusedElement);
};
