import * as Sentry from '@sentry/nextjs';
import type { FunctionComponent } from 'react';
import React, { useState, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { ReactSVG } from 'react-svg';

import IntersectionObserver from 'src/components/shared/IntersectionObserver/IntersectionObserver';

import styles from './Svg.module.scss';

import { randomString } from 'src/utils/StringUtils';

const fillElement = (
  element: Element,
  color: string | undefined,
  randomId: string,
) => {
  if (element.children) {
    Array.from(element.children).forEach((children) => {
      const className = children
        .getAttribute('class')
        ?.replace(/cls/g, randomId);
      if (className) {
        children.setAttribute(
          'class',
          children.getAttribute('class')?.replace(/cls/g, randomId) ?? '',
        );
      }
      if (color) {
        children.setAttribute('fill', color);
        children.removeAttribute('class');
      }

      if (children.children) {
        fillElement(children, color, randomId);
      }
    });
  }
};

type SvgProps = {
  src: string;
  className?: string;
  color?: string;
  height: number;
  width: number;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  shouldRandomizeClipPathId?: boolean;
};

const Svg: FunctionComponent<SvgProps> = ({
  src,
  className,
  color,
  height,
  width,
  onClick,
  onMouseEnter,
  onMouseLeave,
  shouldRandomizeClipPathId = true,
  ...otherProps
}) => {
  const [tries, setTries] = useState(0);
  const onError = useCallback(
    (error: unknown) => {
      if (error && tries < 10) {
        setTimeout(
          () => setTries((oldTries) => oldTries + 1),
          100 * 2 ** tries + Math.random() * 100 * 2 ** tries,
        );
      } else if (error) {
        Sentry.captureException(error);
      }
    },
    [tries],
  );

  const beforeInjection = useCallback(
    (element: Element) => {
      const randomId = randomString(8);
      element.innerHTML = element.innerHTML.replace(/cls/g, randomId);
      element.setAttribute('id', randomId);
      element.setAttribute('data-name', randomId);
      element.setAttribute('width', width.toString());
      element.setAttribute('height', height.toString());
      fillElement(element, color, randomId);
    },
    [color, height, width],
  );

  return (
    <IntersectionObserver className={className} onClick={onClick}>
      {({ isVisible }) => (
        <div
          className={styles.wrapper}
          style={{ width, height }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {isVisible && (
            <ReactSVG
              src={`${src}?type=svg${tries ? `&retries=${tries}` : ''}`}
              onError={onError}
              beforeInjection={beforeInjection}
              renumerateIRIElements={shouldRandomizeClipPathId}
              {...otherProps}
            />
          )}
        </div>
      )}
    </IntersectionObserver>
  );
};

export default Svg;
