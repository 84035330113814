import type { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import axios from 'axios';
import { camelizeKeys } from 'humps';
import { stringify } from 'qs';
import { useRef, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import type { SWRConfiguration, SWRResponse } from 'swr';
// eslint-disable-next-line no-restricted-imports
import useSWRImmutable from 'swr/immutable';

type FetcherReturn<Data> = {
  data: Data | undefined;
  headers: Record<string, unknown>;
};

type NetworkConfig<Variables extends Record<string, unknown>> = {
  method?: AxiosRequestConfig['method'];
  url: string;
  params?: Variables;
};

export const swrFetcher =
  <Data, Variables extends Record<string, unknown>>({
    method = 'GET',
    url,
    params,
  }: NetworkConfig<Variables>) =>
  async () => {
    const instance = axios.create();

    // Axios middleware to convert all API responses to camelCase
    instance.interceptors.response.use((res) => {
      if (
        res.data &&
        res.headers &&
        res.headers['content-type'].includes('application/json')
      ) {
        res.data = camelizeKeys(res.data);
      }

      return res;
    });

    const { data, headers } = await instance.request<Data>({
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      method,
      url,
      params,
      paramsSerializer: { serialize: (p) => stringify(p) },
    });

    return { data, headers };
  };

export type UseQueryConfig<
  Data,
  Variables extends Record<string, unknown> | undefined,
> = SWRConfiguration<Data> &
  (Variables extends undefined
    ? // eslint-disable-next-line @typescript-eslint/ban-types
      {}
    : {
        params: Variables;
      }) & {
    initialHeaders?: Record<string, unknown>;
    skip?: boolean;
  };

export const usePatchedSWR = <Data>(
  { method = 'GET', url, params }: NetworkConfig<Record<string, unknown>>,
  {
    fallbackData,
    initialHeaders,
    skip,
    ...config
  }: SWRConfiguration<Data> & {
    initialHeaders?: Record<string, unknown>;
    skip?: boolean;
  },
) => {
  const key = [
    method,
    url,
    ...(params
      ? Object.entries(params ?? {}).map(
          (arg) => `${arg[0]}${JSON.stringify(arg[1])}`,
        )
      : []),
  ];

  const initialKey = useRef(key);

  const patchedFallbackData =
    (fallbackData || initialHeaders) &&
    key.toString() === initialKey.current.toString()
      ? { data: fallbackData, headers: initialHeaders || {} }
      : undefined;

  // We need to use any, else TypeScript encounters an internal error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = useSWRImmutable<any>(
    !skip ? key : null,
    swrFetcher({ method, url, params }),
    {
      ...config,
      fallbackData: patchedFallbackData,
    },
  ) as SWRResponse<FetcherReturn<Data>, unknown>;

  return {
    ...res,
    data: res.data?.data,
    headers: res.data?.headers
      ? (JSON.parse(JSON.stringify(res.data.headers)) as RawAxiosRequestHeaders)
      : undefined,
  };
};

export type UseMutationOptions<Data> = {
  onCompleted?: (data: Data) => void;
  onError?: (err: Error) => void;
};

export const useMutation = <Data, MutateArgs extends unknown[]>(
  mutate: (...args: MutateArgs) => Promise<Data>,
  options?: UseMutationOptions<Data>,
) => {
  const [data, setData] = useState<Data | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const wrappedMutate = async (...args: Parameters<typeof mutate>) => {
    if (loading) {
      return null;
    }

    setLoading(true);
    setError(null);
    setData(null);

    try {
      const res = await mutate(...args);
      setData(res);
      setLoading(false);

      options?.onCompleted?.(res);

      return res;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }

      setLoading(false);

      if (err instanceof Error) {
        options?.onError?.(err);
      }

      return err;
    }
  };

  return [wrappedMutate, { data, loading, error }] as const;
};
