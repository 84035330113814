import type { FunctionComponent } from 'react';
import React from 'react';

import { mergeClassNames } from '../../../utils/ReactUtils';
import Icon from '../Icon/Icon';
import type { IconType } from '../Icon/types';
import style from './Rating.module.scss';

export type RatingProps = {
  averageRating: number;
  minimized?: boolean;
  iconSize?: number;
};

const Rating: FunctionComponent<RatingProps> = ({
  averageRating,
  minimized = false,
  iconSize = 16,
}) => {
  const decimal_portion = averageRating?.toFixed(2).toString().split('.')[1];
  const precision = Math.round(parseInt(decimal_portion) / 10) * 10;
  const fullStars = Math.floor(averageRating);

  let emptyStars = 5 - fullStars;
  if (precision !== 0) emptyStars--;

  const renderStar = (name: IconType, index = 0) => {
    const iconDimension = minimized
      ? { width: 10, height: 10 }
      : { width: iconSize, height: iconSize };
    return (
      <Icon
        name={name}
        key={index}
        className={mergeClassNames([style.star, minimized && style.minimized])}
        {...iconDimension}
      />
    );
  };
  const renderFullStars = [];
  for (let index = 0; index < fullStars; index++) {
    renderFullStars.push(renderStar('etoile-100', index));
  }

  const renderEmptyStars = [];
  for (let index = 0; index < emptyStars; index++) {
    renderEmptyStars.push(renderStar('etoile-vide', index));
  }
  const renderPrecision = renderStar(
    `etoile-${precision > 0 ? precision : 'vide'}` as IconType,
  );
  return (
    <React.Fragment>
      {renderFullStars}
      {precision !== 0 ? renderPrecision : null}
      {renderEmptyStars}
    </React.Fragment>
  );
};

export default Rating;
