import type { FunctionComponent } from 'react';
import React from 'react';

import styles from './Row.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type RowProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  dataTestId?: string;
};

const Row: FunctionComponent<RowProps> = ({
  className,
  onClick,
  children,
  dataTestId,
}) => {
  return (
    <div
      data-testid={dataTestId}
      className={mergeClassNames([styles.row, className])}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Row;
