// eslint-disable-next-line no-restricted-imports
import Observer from '@researchgate/react-intersection-observer';
import type { FunctionComponent, ReactElement } from 'react';
import React, { useState } from 'react';

import { isClientSide } from 'src/utils/EnvUtils';
import { useNullFirstRender } from 'src/utils/ReactUtils';

const hasNativeSupport =
  isClientSide() &&
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype;

type IntersectionObserverProps = {
  children: (obj: { isVisible: boolean }) => ReactElement;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const IntersectionObserver: FunctionComponent<IntersectionObserverProps> = ({
  children,
  className,
  onClick,
}) => {
  const [visible, setVisible] = useState(false);

  const handleChange = ({ isIntersecting }: IntersectionObserverEntry) => {
    setTimeout(() => {
      setVisible(isIntersecting);
    });
  };

  const isVisible = !hasNativeSupport || visible;

  const Content = (
    <div onClick={onClick} className={className}>
      {useNullFirstRender(children({ isVisible }))}
    </div>
  );

  if (!hasNativeSupport) {
    return Content;
  }

  return <Observer onChange={handleChange}>{Content}</Observer>;
};

export default IntersectionObserver;
