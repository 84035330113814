// eslint-disable-next-line no-restricted-imports
import NextImage from 'next/image';
import type { ComponentProps, FunctionComponent } from 'react';
import React from 'react';

import styles from './Image.module.scss';

const noLoader = ({ src }: { src: string }) => {
  return src;
};

type ImageProps = DistributiveOmit<
  ComponentProps<typeof NextImage>,
  'src' | 'alt' | 'loader'
> & {
  // Disable static image imports (we want to go through imgix)
  src: string;
  alt?: string;
};

const Image: FunctionComponent<ImageProps> = ({
  src,
  layout,
  width,
  height,
  ...otherProps
}) => {
  const finalSrc =
    process.env.NODE_ENV === 'production' &&
    src.startsWith('/') &&
    !src.endsWith('.svg')
      ? `${process.env.NEXT_PUBLIC_IMGIX_URL as string}/frontend${src}`
      : src;

  const loader = finalSrc.startsWith(
    process.env.NEXT_PUBLIC_IMGIX_URL as string,
  )
    ? // Keep default loader (imgix)
      undefined
    : // Use no-loader for non-imgix urls
      noLoader;

  return (
    <div className={layout === 'fill' ? styles.fill : undefined}>
      <NextImage
        {...otherProps}
        src={finalSrc
          // Bypass the mandatory images.path in next.config.js
          .replace(process.env.NEXT_PUBLIC_IMGIX_URL as string, '')}
        layout={layout}
        width={width}
        height={height}
        loader={loader}
        unoptimized={!!loader}
        data-ot-ignore
      />
    </div>
  );
};

export default Image;
