import type { FunctionComponent } from 'react';
import React from 'react';

import { Icon, Link } from '../..';
import type { IconType } from '../../Icon/types';
import styles from './ButtonPrimary.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type ButtonPrimaryProps = {
  url?: string;
  color?: 'White' | 'Brown';
  leftIcon?: string;
  rightIcon?: string;
  iconWidth?: number;
  iconHeight?: number;
  handleOnClick?: () => void;
} & JSX.IntrinsicElements['button'];

const ButtonPrimary: FunctionComponent<ButtonPrimaryProps> = ({
  className,
  children,
  url,
  color,
  leftIcon,
  rightIcon,
  iconWidth = 16,
  iconHeight = 16,
  handleOnClick,
  ...otherProps
}) => {
  const buttonClassName = mergeClassNames([
    styles.btnPrimary,
    color && styles['btnPrimary' + color],
    className,
  ]);

  const Content = (
    <>
      {leftIcon && (
        <Icon
          name={leftIcon as IconType}
          width={iconWidth}
          height={iconHeight}
          color="inherit"
          className={styles.content}
        />
      )}
      <span className={styles.content}>{children}</span>
      {rightIcon && (
        <Icon
          className={styles.content}
          name={rightIcon as IconType}
          width={iconWidth}
          height={iconHeight}
          color="inherit"
        />
      )}
    </>
  );

  if (url) {
    return (
      <Link href={url} onClick={handleOnClick} className={buttonClassName}>
        {Content}
      </Link>
    );
  }

  return (
    <button type={'button'} className={buttonClassName} {...otherProps}>
      {Content}
    </button>
  );
};

export default ButtonPrimary;
