import type { FunctionComponent } from 'react';
import React from 'react';

import { Link } from '../..';
import Icon from '../../Icon/Icon';
import type { IconType } from '../../Icon/types';
import styles from './ButtonSecondary.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

export type ButtonSecondaryProps = {
  url?: string;
  color?: 'Brown' | 'White';
  leftIcon?: IconType;
  rightIcon?: IconType;
} & JSX.IntrinsicElements['button'];

const ButtonSecondary: FunctionComponent<ButtonSecondaryProps> = ({
  className,
  children,
  url,
  color,
  leftIcon,
  rightIcon,
  ...otherProps
}) => {
  const buttonClassName = mergeClassNames([
    styles.btnSecondary,
    color && styles['btnSecondary' + color],
    className,
  ]);

  const Content = (
    <>
      {leftIcon && (
        <Icon
          name={leftIcon}
          className={styles.content}
          width={32}
          height={32}
        />
      )}
      <span className={styles.content}>{children}</span>
      {rightIcon && (
        <Icon
          name={rightIcon}
          className={mergeClassNames([styles.iconRight, styles.content])}
          width={32}
          height={32}
        />
      )}
    </>
  );

  if (url) {
    return (
      <Link href={url} className={buttonClassName}>
        {Content}
      </Link>
    );
  }

  return (
    <button type={'button'} className={buttonClassName} {...otherProps}>
      {Content}
    </button>
  );
};

export default ButtonSecondary;
