import type { FunctionComponent, ButtonHTMLAttributes } from 'react';
import React from 'react';

import { Icon } from '../..';
import type { IconType } from '../../Icon/types';

export type IconButtonProps = {
  leftIcon?: string;
  rightIcon?: string;
};

const IconButton: FunctionComponent<
  IconButtonProps & ButtonHTMLAttributes<unknown>
> = ({
  leftIcon,
  rightIcon,
  children,
  ...otherProps
}: IconButtonProps & ButtonHTMLAttributes<unknown>) => {
  return (
    <button className={otherProps.className} {...otherProps}>
      {leftIcon && <Icon name={leftIcon as IconType} width={32} height={32} />}
      {children}
      {rightIcon && (
        <Icon name={rightIcon as IconType} width={32} height={32} />
      )}
    </button>
  );
};

export default IconButton;
