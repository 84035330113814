import type { FunctionComponent } from 'react';
import React from 'react';

import style from './Price.module.scss';

import { mergeClassNames } from 'src/utils/ReactUtils';

type PriceProps = {
  price: string;
  className?: string;
  exponent?: string;
  separator?: string;
  shouldAddExponent?: boolean;
};

const Price: FunctionComponent<PriceProps> = ({
  price,
  exponent = '€',
  separator = ',',
  className,
  shouldAddExponent = true,
  ...otherProps
}) => {
  if (!shouldAddExponent) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: price.replace(/,/g, ' ').replace(/\s/g, '&nbsp;'),
        }}
        {...otherProps}
      />
    );
  }

  const actualPriceSeparator = price.includes(',') ? ',' : '.';

  const splitPrice =
    price && !Number.isInteger(price)
      ? price.toString().split(actualPriceSeparator)
      : price;

  return (
    <div className={mergeClassNames([style.price, className])} {...otherProps}>
      <span data-testid="price_integer">
        {typeof splitPrice === 'object' ? splitPrice[0] : splitPrice}
      </span>
      {separator !== null && (
        <sup className={style.priceCents} data-testid="price_decimal">
          {typeof splitPrice === 'object' && splitPrice[1] !== undefined
            ? `${separator}${splitPrice[1]}`
            : `${separator}00`}
          {exponent}
        </sup>
      )}
    </div>
  );
};

export default Price;
