import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';

import styles from './Title4.module.scss';

export type Title4Props = {
  className?: string;
  children: ReactNode;
};

const Title4: FunctionComponent<Title4Props> = ({ className, children }) => {
  return <div className={`${styles.title4} ${className}`}>{children}</div>;
};

export default Title4;
